<template>
    <div class="vue-template auth">
        <form @submit.prevent="profileUpdate">
            <h3>Update User Profile</h3>
            <div class="d-grid gap-3">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control form-control-lg" v-model="user.name" />
                </div>

                <div>
                    <label>Select Event</label>
                    <select v-model=currentDbId id="dbName" class="form-select form-control form-control-sm">
                        <option value=0>Please select event to view</option>
                        <option v-for="name in collectionNames" :key="name.Id" :value="name.Id">
                            {{name.Name}}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control form-control-lg" v-model="user.email" />
                </div>

                <div class="form-group">
                    <label>Password</label>
                    <input type="password" class="form-control form-control-lg" v-model="user.password" />
                </div>

                <button type="submit" class="btn btn-primary">Save Profile</button>
                <button class="btn btn-secondary" @click.prevent="onCancel" >Exit</button>

            </div>
        </form>
    </div>
</template>


<script>
//import { auth } from '@/services/firebase/index.js';

export default {
    data() {
        return {
            currentDbId: 0,
            user: {
                name: '',
                email: '',
                password: ''
            }
        };
    },
    methods: {
        profileUpdate() {
            this.$router.push({ name: 'Score' });
        },
        writeUserData() {
            
        },
        onCancel() {
            this.$router.push({ name: 'Score' });
        }
    },
    computed: {
        collectionNames() {
            return this.$store.getters.getCollectionNames;
        }
    }
};
</script>
